import { loadScript } from '../shared/loadScript';

enum EAccount {
  DEFAULT = 'arkadium',
  DICTIONARY = 'arkadium-dictionary',
  INSP = 'arkadium-insp',
}

interface IAdLightningConfig {
  accountName: EAccount;
}

export class AdLightning {
  static instance?: AdLightning;

  static getInstance(config?: IAdLightningConfig): AdLightning {
    if (!this.instance) {
      this.instance = new AdLightning(config || AdLightning.getDefaultConfig());
    }

    return this.instance;
  }

  static getDefaultConfig(): IAdLightningConfig {
    const hostMap: { [key: string]: EAccount } = {
      'dictionary.arkadiumarena.com': EAccount.DICTIONARY,
      'games.dictionary.com': EAccount.DICTIONARY,
      'games.insp.com': EAccount.INSP,
    };

    const accountName = hostMap[window.location.hostname] || EAccount.DEFAULT;

    return { accountName };
  }

  protected _loaded: Promise<any>;

  constructor(config: IAdLightningConfig) {
    if (window.location.hostname !== 'www.arkadium.com') {
      this._loaded = loadScript(`https://tagan.adlightning.com/${config.accountName}/op.js`);
    } else {
      this._loaded = Promise.resolve();
    }
  }
}
