export interface Config {
  name: string;
  globalName: string;
  isAmazonEnabled: boolean;
  isCleanIOEnabled: boolean;
  isAditudeEnabled: boolean;
  refreshTime: number;
  bundleUrl: string;
  bids: any[];
  userIds: any[];
}

export interface JarvisResponse {
  name: string;
  mcm?: string;
  schain?: string;
  customParams?: any;
  config: Config;
}

const API_URL = `https://arenacloud.cdn.arkadiumhosted.com/jarvis-api-prod`;

export class ConfigService {
  public fetched: Promise<JarvisResponse>;

  constructor(protected host: string) {
    this.fetched = fetch(`${API_URL}/v2/domain/get/${this.host}`).then(r => r.json());
  }

  fetch() {
    return this.fetched;
  }
}
