import { AdsService } from "./services/ads-service";
import { DisplayAd } from "./display-ad";
import { ArkGoogleTag } from "./legacy";
import { GeoService } from "./services/geo";

interface IArkAdsGlobal {
  geo?: GeoService;
  core?: AdsService;
  displayAd?: { }
  displayAdReady?: (fn: Function) => void;
}

declare global {
  interface Window {
    __ark_ads__: IArkAdsGlobal;
  }
}

const maybe = <T>(fn: () => T) => {
  try {
    return fn();
  } catch (e) {
    return;
  }
};

function getHostname() {
  if (location.search.includes('ark_display_debug')) return 'www.gamelab.com';
  if (window === top) return window.location.hostname;
  return maybe(() => window.top?.location.hostname)
    || maybe(() => (new URL(document.referrer)).hostname)
    || 'default';
}

function getCleanHostname() {
  const hostname = getHostname();
  if (hostname.includes('googlesyndication')) return 'safeframe.googlesyndication.com';
  return hostname;
}

window.__ark_ads__ = window.__ark_ads__ || {};
window.__ark_ads__.core = window.__ark_ads__.core || AdsService.getInstance(getCleanHostname());
window.__ark_ads__.displayAd = window.__ark_ads__.displayAd || { ArkGoogleTag };
window.__ark_ads__.displayAdReady = (fn: Function) => { fn() };

defineCustomElement(DisplayAd);

function defineCustomElement(Component: CustomElementConstructor, customTag = 'display-ad-component') {
    if (!customElements.get(customTag)) {
        customElements.define(customTag, Component);
    }
}
