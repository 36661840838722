import { loadScript } from "../shared/loadScript";

import { JarvisResponse } from './config-service';
import { GeoService } from "./geo";

declare global {
  interface Window {
    [K: string]: any;
  }
}

const TIMEOUT = 2500;
const GDPRCountryCodes = [ 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'GF', 'GP', 'MQ', 'ME', 'YT', 'RE', 'MF', 'GI', 'AX', 'PM', 'GL', 'BL', 'SX', 'AW', 'CW', 'WF', 'PF', 'NC', 'TF', 'AI', 'BM', 'IO', 'VG', 'KY', 'FK', 'MS', 'PN', 'SH', 'GS', 'TC', 'AD', 'LI', 'MC', 'SM', 'VA', 'JE', 'GG', 'GI', 'CH', ];

function getEids() {
  let id = (document.cookie.match(/ai_user=(.+?);/) || [])[1];
  if (!id) {
    try {
      id = localStorage.getItem('adsid') || '';
      if (!id) {
        id = btoa(Math.random().toString());
        localStorage.setItem('adsid', id);
      }
    } catch (e) {
      id = 'anon';
    }
  }
  return { source: 'arkadium.com', uids: [{ atype: 1, ext: { stype: 'ppuid' }, id }] };
}

const defaultUserIds = [
  { name: 'lotamePanoramaId' },
  { name: 'sharedId', storage: { type: 'cookie', name: '_pubcid', expires: 365 } },
  { name: 'uid2' },
  { name: 'idx' },
  { name: 'criteo' },
  { name: 'pubProvidedId', params: { eidsFunction: getEids } },
];

export class PrebidService {
  protected pb: any;
  protected que: any[];
  protected bids: any[];
  protected userIds: any[];
  protected prebidLoaded: Promise<any>;
  protected prebidConfigured: Promise<any>;
  protected schain: any;

  constructor(config: JarvisResponse, protected dfpNetworkCode: string, protected inventoryCode: string) {
    this.bids = config.config.bids;
    const jarvisUserIds = config.config.userIds.map((u) => {
      if (['criteo', 'lotamePanoramaId'].includes(u.name)) return { name: u.name };
      return u;
    });

    this.userIds = jarvisUserIds.length > 0 ? jarvisUserIds : defaultUserIds;

    if (config.schain) {
      this.schain = {
        validation: 'relaxed',
        config: {
          ver: '1.0',
          complete: 1,
          nodes: [{ asi: 'arkadium.com', sid: config.schain, hp: 1 }],
        },
      };
    }

    const { globalName, bundleUrl, bids } = config.config;

    window[globalName] = window[globalName] || {};
    window[globalName].que = window[globalName].que || [];

    this.pb = window[globalName];
    this.que = this.pb.que;

    this.prebidLoaded = loadScript(bundleUrl);
    this.prebidConfigured = this.configurePrebid(bids);
  }

  public async addAdUnit(adUnit: any) {
    const slotName = `/${this.dfpNetworkCode}/${this.inventoryCode}/${adUnit.id}`;
    await this.prebidConfigured;
    this.run((prebid) => prebid.addAdUnits({
      code: adUnit.id,
      bids: this.bids.map(({ bidder, params }) => ({ bidder, params })),
      sizes: adUnit.size,
      mediaTypes: {
        banner: { sizes: adUnit.size },
      },
      ortb2Imp: {
        ext: {
          data: {
            adserver: {
              adslot: slotName,
              name: "gam"
            },
            pbadslot: slotName
          },
          gpid: slotName
        },
      },
    }));
  }

  public removeAdUnit(adUnit: any) {
    this.run((prebid) => {
      prebid.removeAdUnit(adUnit.id);
    });
  }

  public requestBids(adUnitCodes: any[]) {
    return new Promise(async (resolve, reject) => {
      const timeout = setTimeout(resolve, TIMEOUT);
      await this.prebidConfigured;
      this.run((pbjs) => {
        pbjs.requestBids({
          adUnitCodes,
          timeout: TIMEOUT,
          bidsBackHandler: (bids: any[]) => {
            clearTimeout(timeout);
            window.googletag.cmd.push(() => {
              pbjs.setTargetingForGPTAsync();
              resolve(bids);
            });
          }
        });
      });
    });
  }

  protected run(cmd: (prebid: any) => void) {
    this.que.push(() => cmd(this.pb));
  }

  protected async configurePrebid(config: any) {
    this.run((prebid) => {
      prebid.bidderSettings = {
        standard: {
          storageAllowed: true,
          adserverTargeting: [
            { key: 'hb_cpm', val(bid: any) { return bid.cpm.toFixed(2); } },
            { key: 'hb_pb', val(bid: any) { return bid.cpm.toFixed(2); } },
            { key: 'hb_adid', val(bid: any) { return bid.adId; } },
            { key: 'hb_bidder', val(bid: any) { return bid.adapterCode } },
          ]
        },
        pubmatic: { bidCpmAdjustment: (cpm: number) => cpm * 0.8 },
        rubicon: { bidCpmAdjustment: (cpm: number) => cpm * 0.8 },
        improvedigital: { bidCpmAdjustment: (cpm: number) => cpm * 0.75 },
        smaato: { bidCpmAdjustment: (cpm: number) => cpm * 0.75 },
        smartadserver: { bidCpmAdjustment: (cpm: number) => cpm * 0.8 },
        spotx: { bidCpmAdjustment: (cpm: number) => cpm * 0.75 },
      };
    });

    const consentManagement: any = {};
    const staticUsp = {
      cmpApi: 'static',
      consentData: { 
        getUSPData: { uspString: '1---' },
      },
    };

    const geo = await GeoService.getInstance().getGeo();
    
    if (geo) {
      if (GDPRCountryCodes.includes(geo.country_code) && window.__tcfapi) {
        consentManagement.gdpr = { cmpApi: 'iab' };
        consentManagement.usp = staticUsp;
      } else if (geo.country_code === 'US') {
        if (geo.region_code === 'CA' && window.__uspapi) {
          consentManagement.usp = { cmpApi: 'iab' };
        } else {
          consentManagement.usp = staticUsp;
        }
      }
    }


    const pbConfig = {
      consentManagement,
      firstPartyData: { enabled: true, skipEnrichments: false },
      gptPreAuction: { enabled: true, mcmEnabled: true },
      enableTIDs: true,
      bidderSequence: 'random',
      bidderTimeout: TIMEOUT - 100,
      deviceAccess: true,
      pageUrl: location.href,
      useBidCache: true,
      enableSendAllBids: false,
      cache: { url: 'https://prebid.adnxs.com/pbc/v1/cache', ignoreBidderCacheKey: false },
      improvedigital: { usePrebidSizes: true },
      userSync: {
        iframeEnabled: true,
        syncEnabled: true,
        syncsPerBidder: 50,
        syncDelay: 100,
        auctionDelay: 300,
        filterSettings: {
          all: { bidders: '*', filter: 'include' },
        },
        userIds: this.userIds,
      },
      s2sConfig: [
        {
          defaultVendor: 'rubicon',
          accountId: '17214',
          bidders: [],
          timeout: TIMEOUT - 100,
        },
      ],
      schain: this.schain,
      floors: {
        data: {
          schema: { fields: ['mediaType', 'size'] },
          values: { 'banner|*': 0.05 },
          default: 0.05,
        }
      },
      bidViewability: {
        enabled: true,
        firePixels: true,
      },
      disableAjaxTimeout: true,
      allowActivities: {
        accessDevice: { default: true },
        enrichEids: { default: true },
        enrichUfpd: { default: true },
        fetchBids: { default: true },
        syncUser: { default: true },
        transmitEids: { default: true },
        transmitPreciseGeo: { default: true },
        transmitTid: { default: true },
        transmitUfpd: { default: true },
      }
    };

    return new Promise((resolve) => {
      this.run((pbjs) => pbjs.setConfig(pbConfig));
      resolve(true);
    })
  }
}
