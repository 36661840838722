import { AdServer } from "./adserver";
import { PrebidService } from "./prebid-service";
import { ConfigService } from "./config-service";
import { AmazonService } from "./amazon-service";
import { AditudeFloors } from "./aditude-floors";
import { AdLightning } from "./ad-lightning";
import { CleanIO } from "./clean-io";
import { loadScript } from "../shared/loadScript";
import { GeoService } from "./geo";
import { ReportingService } from "./reporting";


let _instance: AdsService;

export interface AdUnit {
  size: number[][];
  id: string;
  kv?: string[][];
  product?: string;
  lastRefreshed: number;
}

export class AdsService {
  static getInstance(host?: string) {
    if (_instance) {
      return _instance;
    }

    if (!host) {
      throw Error('Host is lost!');
    }

    return _instance = new AdsService(host);
  }

  protected dfpNetworkCode = '100151972';
  protected refreshTime = 30;
  protected scheduled: any;
  protected configService: ConfigService;
  protected prebid?: PrebidService;
  protected amazon?: AmazonService;
  protected aditude?: AditudeFloors;
  protected adServer?: AdServer;
  protected adUnits: AdUnit[] = [];
  protected lightning: AdLightning;
  protected cleanio: CleanIO;
  protected reporting: ReportingService;

  public onShowGTMEvent?: (ads: string[], isRefresh: boolean, eventDetail: { isEmpty: boolean, cpm: number }) => void;

  constructor(
    protected host: string,
  ) {
    GeoService.getInstance();
    this.cleanio = new CleanIO(loadScript);
    this.configService = new ConfigService(this.host);
    this.lightning = AdLightning.getInstance();
    this.configService.fetched.then(resp => {
      const { mcm, config } = resp;

      this.cleanio.init(config);
      this.refreshTime = config.refreshTime;
      this.aditude = new AditudeFloors(config);
      this.adServer = new AdServer({ mcm, dfpNetworkCode: this.dfpNetworkCode, inventoryCode: this.host });
      this.adServer.on('slotRenderEnded', this.slotRenderEndedHandler.bind(this));
      this.prebid = new PrebidService(resp, this.dfpNetworkCode, this.host);

      if (config.isAmazonEnabled) {
        this.amazon = new AmazonService();
      }

      setInterval(() => this.scheduleAdRequest(), 250);
    });
    this.reporting = new ReportingService();
  }

  public async registerAdUnit(adUnit: AdUnit) {
    await this.configService.fetched;

    this.adUnits.push(adUnit);
    this.prebid?.addAdUnit(adUnit);
    this.adServer?.createAdUnit(adUnit);

    this.scheduleAdRequest();
  }

  public unregisterAdUnit(adUnit: AdUnit) {
    this.adUnits = this.adUnits.filter(a => a.id !== adUnit.id);
    this.adServer?.removeAdUnit(adUnit);
    this.prebid?.removeAdUnit(adUnit);
  }

  public scheduleAdRequest() {
    if (this.scheduled) clearTimeout(this.scheduled);
    this.scheduled = setTimeout(() => this.requestAds(), 200);
  }

  protected requestAds() {
    if (!this.prebid) return;
    if (!document.hasFocus() && window.self === window.top) return; // always render in iframe

    const adUnitIdsToRequest = this.adUnits
      .filter(this.isAdUnitVisible.bind(this))
      .filter(this.isAdUnitExpired.bind(this))
      .map(this.setAdUnitRefreshed.bind(this));

    if (adUnitIdsToRequest.length === 0) return;

    const amazonAdUnitsToRequest = adUnitIdsToRequest.map(slotID => ({
      slotID,
      slotName: this.adServer?.getAdUnitCode(slotID) || '',
      sizes: this.adUnits.find(a => a.id === slotID)?.size || [],
    }));

    return Promise.all([
      this.prebid?.requestBids(adUnitIdsToRequest) || Promise.resolve(),
      this.amazon?.requestBids(amazonAdUnitsToRequest) || Promise.resolve(),
    ]).then(() => this.adServer?.refresh(adUnitIdsToRequest));
  }

  protected setAdUnitRefreshed(a: AdUnit): AdUnit['id'] {
    a.lastRefreshed = Date.now();
    return a.id;
  }

  protected isAdUnitExpired(u: AdUnit) {
    const lastRefreshed = u.lastRefreshed || 0;
    return Date.now() > (lastRefreshed + this.refreshTime * 1000);
  }

  public isAdUnitVisible(unit: AdUnit) {
    const el = document.getElementById(unit.id);
    const win = window;

    if (!el) return false;
    if (!el.parentElement) return false;
    if (el.style.display === 'none') return false;

    const bounds = el.getBoundingClientRect();
    const viewabilityPercent = .5;

    const visibleVertically = (
        bounds.top + bounds.height * viewabilityPercent <= win.innerHeight
        && bounds.bottom - bounds.height * viewabilityPercent >= 0
    );

    const visibleHorizontally = (
        bounds.left + bounds.width * viewabilityPercent <= win.innerWidth
        && bounds.right - bounds.width * viewabilityPercent >= 0
    );

    return (
        visibleVertically
        && visibleHorizontally
    );
  }

  protected slotRenderEndedHandler(event: any) {
    const map = event.slot.getTargetingMap();
    const isHouseAd = event.slot.lineItemId === 78658612;
    const isEmpty = event.slot.isEmpty;

    // IDK why Google supplies arrays here
    const hb_cpm = Number(map.hb_cpm && map.hb_cpm[0]);
    const hb_bidder = map.hb_bidder && map.hb_bidder[0];
    const elementId = map.elementId[0];
    const refreshCount = Number(map.refreshCount && map.refreshCount[0]);

    if (hb_cpm > 0 && !isHouseAd && !isEmpty) {
      // TODO: Make EYE doing less requests
      //Eye.trackImpression('display', hb_bidder, hb_cpm, { elementId } as any);
    }

    if (!this.onShowGTMEvent) {
      console.log('AdUnit rendered: ', event);
      return;
    }

    const isRefresh = refreshCount > 0;
    const eventDetails = {
        isEmpty: event.isEmpty,
        size: event.size,
        cpm: Number(hb_cpm),
    };

    try {
      this.onShowGTMEvent([elementId], isRefresh, eventDetails);
    } catch (e) {
      console.warn(e);
    }
  }
}

